import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import CaretDownGrey from '../../../../../assets/icons/filter-caret-down-grey.svg';
import CaretDownTeal from '../../../../../assets/icons/filter-caret-down-teal.svg';
import CaretUpGrey from '../../../../../assets/icons/filter-caret-up-grey.svg';
import CaretUpTeal from '../../../../../assets/icons/filter-caret-up-teal.svg';
import { KLCheckbox } from '../../../../../components';
import './ReportStatusFilter.scss';
import { ReportStatus } from '../../../../../redux/types';

interface ReportStatusFilterProps {
  onChange: (value: ReportStatus) => void;
  reportStatus: ReportStatus;
}

export default function ReportStatusFilter({
  onChange,
  reportStatus
}: ReportStatusFilterProps): ReactElement {
  const [showFiltersMenu, setShowFiltersMenu] = useState(false);
  const useOutsideClick = (show: boolean, setShow: (show: boolean) => void) => {
    const menuRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setShow(false);
      }
    };

    useEffect(() => {
      if (show) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [show, setShow]);

    return { menuRef, buttonRef };
  };

  const { menuRef, buttonRef } = useOutsideClick(
    showFiltersMenu,
    setShowFiltersMenu
  );

  const hanldeChange = (label: string) => {
    onChange({
      ...reportStatus,
      [label]: !reportStatus[label as keyof ReportStatus]
    });
  };

  const numbActiveFilters = useMemo(() => {
    return Object.values(reportStatus).reduce((count, value) => {
      if (value) count++;
      return count;
    }, 0);
  }, [reportStatus]);

  return (
    <div className="report-status-filter">
      {Object.values(reportStatus).every(v => v === false) ? (
        <button
          ref={buttonRef}
          className="report-status-filter__button"
          onClick={() => setShowFiltersMenu(prev => !prev)}>
          <div className="report-status-filter__text">Report Status</div>
          <div className="report-status-filter__icon">
            <img
              src={showFiltersMenu ? CaretUpGrey : CaretDownGrey}
              className="report-status-filter__icon-caret"
            />
          </div>
        </button>
      ) : (
        <button
          ref={buttonRef}
          className="report-status-filter__button--teal"
          onClick={() => setShowFiltersMenu(prev => !prev)}>
          <div className="report-status-filter__text--teal">
            Report Status ({numbActiveFilters})
          </div>
          <div className="report-status-filter__icon">
            <img
              src={showFiltersMenu ? CaretUpTeal : CaretDownTeal}
              className="report-status-filter__icon-caret"
            />
          </div>
        </button>
      )}

      {showFiltersMenu && (
        <div className="report-status-filter__menu" ref={menuRef}>
          <KLCheckbox
            label="Not Started"
            checked={reportStatus.notStarted}
            onChange={() => {
              hanldeChange('notStarted');
            }}
          />
          <KLCheckbox
            label="Processing"
            checked={reportStatus.processing}
            onChange={() => {
              hanldeChange('processing');
            }}
          />
          <KLCheckbox
            label="Ready for Review"
            checked={reportStatus.readyForReview}
            onChange={() => {
              hanldeChange('readyForReview');
            }}
          />
          <KLCheckbox
            label="Sent to Patient"
            checked={reportStatus.sentToPatient}
            onChange={() => {
              hanldeChange('sentToPatient');
            }}
          />
        </div>
      )}
    </div>
  );
}
