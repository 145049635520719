/* eslint-disable @typescript-eslint/no-explicit-any */
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import {
  AuthorizationError,
  BadRequestError,
  DirectUpload,
  RequestMethod,
  callStratusApiV2
} from '../../api';
import { filtersToParams, surgeryInfoToSurgery } from '../../utils/surgeryData';
import { isNullOrUndefined } from '../../utils/validations';
import store from '../store';
import {
  AnnotatedData,
  EmergencyContactFormData,
  FilterData,
  KLExternalReview,
  KLSurgery,
  NewSurgeryData,
  PatientFormData,
  ReportStatus,
  SurgeonBio,
  SurgeryFormData,
  SurgeryInfo,
  UploadSurgeryData
} from '../types';
import { setProgress } from './uiActions';

/**
 * Retrieves the list of surgeries for the logged-in user.
 *
 * @param accessToken - The access token for authentication.
 * @returns A Promise that resolves to an array of KLSurgery objects representing the surgeries.
 */
export async function getSurgeriesForLoggedInUser(
  accessToken: string,
  filters?: FilterData,
  reportStatus?: ReportStatus,
  page?: number
): Promise<KLSurgery[]> {
  const surgeons = store.getState().surgeons;
  const facilities = store.getState().facilities.facilities;

  const params = filtersToParams(
    filters,
    reportStatus,
    page,
    surgeons,
    facilities
  );

  // Make an API call to retrieve the surgeries
  const res = await callStratusApiV2<{
    data: KLSurgery[];
    pages: number;
    totalSurgeries: number;
  }>('surgeries?' + params, {
    accessToken,
    method: RequestMethod.Get
  });

  store.dispatch({
    type: 'SET_PAGES',
    payload: res.pages
  });

  store.dispatch({
    type: 'SET_TOTAL_SURGERIES',
    payload: res.totalSurgeries
  });

  store.dispatch({
    type: 'SET_SURGERIES',
    payload: camelcaseKeys(res.data, { deep: true })
  });

  return res.data;
}

/**
 * Creates a new surgery using the provided access token, new surgery data, and current date.
 *
 * @param {string} accessToken - The access token used for authentication.
 * @param {NewSurgeryData} newSurgeryData - The data for the new surgery.
 * @param {string} currentDate - The current date.
 * @return {Promise<number>} A Promise that resolves with the ID of the created surgery.
 */
export async function createSurgery(
  accessToken: string,
  newSurgeryData: NewSurgeryData,
  currentDate: string
): Promise<number> {
  const res = await callStratusApiV2<{ surgeryId: number }>('surgeries', {
    accessToken,
    method: RequestMethod.Post,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      surgery: snakecaseKeys(newSurgeryDataToFormData(newSurgeryData) as any, {
        deep: true
      }),
      current_date: currentDate
    })
  });

  return res.surgeryId;
}
function newSurgeryDataToFormData(data: NewSurgeryData): SurgeryFormData {
  const emergencyContact: EmergencyContactFormData = {
    name: data.emergencyContactName,
    emergencyContactPhone: data.emergencyContactPhone,
    emergencyContactEmail: data.emergencyContactEmail,
    address: data.emergencyContactAddress,
    city: data.emergencyContactCity,
    state: data.emergencyContactState,
    zipcode: data.emergencyContactZipcode
  };

  const patient: PatientFormData = {
    firstName: data.firstName,
    middleName: data.middleName,
    lastName: data.lastName,
    dob: data.dob,
    email: data.email,
    phone: data.phone,
    preferredCommunication: 'email',
    race: data.race,
    sex: data.sex,
    preferredName: data.preferredName,
    pronouns: data.pronouns,
    address: data.address,
    city: data.city,
    state: data.state,
    zipcode: data.zipcode,
    emergencyContact: emergencyContact
  };

  return {
    surgeryDate: data.surgeryDate,
    surgeryType: data.surgeryType,
    side: data.side,
    surgeonId: data.surgeonId,
    facilityId: data.facilityId,
    patient: patient,
    plannedProcedures: data.plannedProcedures
  };
}

function klsurgeryToFormData(data: KLSurgery): SurgeryFormData {
  return {
    surgeryDate: data.surgeryDate,
    surgeryType: data.surgeryType,
    plannedProcedures: data.plannedProcedures,
    performedProcedures: data.performedProcedures,
    side: data.side,
    surgeonId: data.surgeonId,
    facilityId: data.facilityId,
    postOpInstructionsId: data.postOpInstructionsId
  };
}

export async function updateSurgery(
  accessToken: string,
  surgeryId: number,
  surgeryData: KLSurgery
): Promise<void> {
  await callStratusApiV2<void>(`surgeries/${surgeryId}`, {
    accessToken,
    method: RequestMethod.Put,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      surgery: snakecaseKeys(klsurgeryToFormData(surgeryData) as any)
    })
  });
}

export function clearSurgeries(): void {
  store.dispatch({
    type: 'CLEAR_SURGERIES'
  });
}

export async function deleteSurgery(
  accessToken: string,
  surgeryId: number
): Promise<void> {
  await callStratusApiV2<void>(`surgeries/${surgeryId}`, {
    accessToken,
    method: RequestMethod.Delete,
    headers: { 'Content-Type': 'application/json' }
  });
}

//UPLOAD ACTIONS

export function setUploadData(uploadData: UploadSurgeryData): void {
  store.dispatch({
    type: 'SET_UPLOAD_DATA',
    payload: uploadData
  });
}

export function toggleShowConfirmation(val: boolean): void {
  store.dispatch({
    type: 'SET_SHOW_CONFIRMATION',
    payload: val
  });
}

export function toggleNavbarStatus(val: string): void {
  store.dispatch({
    type: 'SET_NAVBAR_STATUS',
    payload: val
  });
}

export function toggleNavbarShow(val: boolean): void {
  store.dispatch({
    type: 'SET_TOGGLE_NAVBAR_SHOW',
    payload: val
  });
}
let controller: AbortController | null = null;
export async function uploadFiles(
  accessToken: string,
  surgeryId: number,
  files: File[]
): Promise<void> {
  resetUploadProgress();
  setProgress(0);
  setNumFilesToUpload(files.length);

  const keepAliveInterval = setInterval(() => {
    const event = new Event('keepAlive');
    window.dispatchEvent(event);
  }, 60000);

  let canceledUpload = false;
  for (const [index, file] of files.entries()) {
    controller = new AbortController();
    // Because we can't use break to return early out of a loop with
    // async code we have to check each iteration if we wanted to cancel
    // the upload. If so we just don't do anything for the iteration.
    canceledUpload = store.getState().surgeries.upload.cancelUpload;
    const uploadError = store.getState().surgeries.upload.uploadError;
    if (!canceledUpload && isNullOrUndefined(uploadError)) {
      try {
        const fileUpload = new DirectUpload(accessToken, surgeryId, file);
        await fileUpload.upload();

        // finalize uploads to update surgery status and start processing
        if (index === files.length - 1) {
          await callStratusApiV2<void>(
            `surgeries/${surgeryId}/finalize_upload`,
            {
              accessToken,
              method: RequestMethod.Post,
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ media_source: 'raw' }),
              signal: controller.signal
            }
          );
        }
        incrementUploadProgress();
      } catch (e) {
        if (e instanceof Error) {
          if (e instanceof DOMException) {
            canceledUpload = true;
          } else {
            setUploadError(e);
          }
        }
      }
    }
  }

  if (canceledUpload) {
    await callStratusApiV2<{ message: string }>(
      `surgeries/${surgeryId}/cancel_upload`,
      {
        accessToken,
        method: RequestMethod.Delete,
        headers: { 'Content-Type': 'application/json' }
      }
    );
  } else {
    toggleShowConfirmation(true);
  }

  clearInterval(keepAliveInterval);
}

export async function trackVisits(
  surgeryId: number | null,
  accessToken: string
): Promise<void> {
  const date = new Date().toISOString();
  await callStratusApiV2<{ message: string }>(
    `metrics/${surgeryId}/report/visits`,
    {
      accessToken,
      method: RequestMethod.Post,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        visitDate: date
      })
    }
  );
}

export function cancelUpload(): void {
  controller?.abort();
  store.dispatch({
    type: 'CANCEL_UPLOAD'
  });
}

export function setUploadError(e: Error | null): void {
  store.dispatch({
    type: 'SET_UPLOAD_ERROR',
    payload: e
  });
}

function setNumFilesToUpload(numFiles: number): void {
  store.dispatch({
    type: 'SET_NUM_FILES_TO_UPLOAD',
    payload: numFiles
  });
}

function incrementUploadProgress(): void {
  store.dispatch({
    type: 'INCREMENT_UPLOAD_PROGRESS'
  });
}

function resetUploadProgress(): void {
  setNumFilesToUpload(0);
  store.dispatch({
    type: 'RESET_UPLOAD_PROGRESS'
  });
}

// REVIEW ACTIONS

export async function getReviewDataById(
  accessToken: string,
  surgeryId: number
): Promise<void> {
  const res = await callStratusApiV2<{
    annotations: AnnotatedData[];
  }>(`surgeries/${surgeryId}/annotations`, {
    accessToken,
    method: RequestMethod.Get
  });
  store.dispatch({
    type: 'SET_REVIEW_DATA',
    payload: res
  });
}

export async function getSurgeryDataById(
  accessToken: string,
  surgeryId: number
): Promise<void> {
  const res = await callStratusApiV2<{
    surgery_info: SurgeryInfo;
    surgeon_bio_info: SurgeonBio;
  }>(`surgeries/${surgeryId}`, {
    accessToken,
    method: RequestMethod.Get
  });
  store.dispatch({
    type: 'SET_SURGERY_DATA',
    payload: res
  });
}

export async function updateReviewDataById(
  accessToken: string,
  surgeryId: number,
  newReviewData: AnnotatedData | AnnotatedData[]
): Promise<void> {
  if (!Array.isArray(newReviewData)) {
    newReviewData = [newReviewData];
  }

  await callStratusApiV2(`surgeries/${surgeryId}/annotations`, {
    accessToken,
    method: RequestMethod.Put,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      annotations: newReviewData
    })
  });
}

export async function finalizeReviewById(
  accessToken: string,
  surgeryId: number,
  annotateVideo: boolean
): Promise<void> {
  const url = annotateVideo
    ? `surgeries/${surgeryId}/annotations?finalize=true&annotateVideo=${annotateVideo}`
    : `surgeries/${surgeryId}/annotations?finalize=true`;
  await callStratusApiV2(url, {
    accessToken,
    method: RequestMethod.Put,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      annotations: []
    })
  });
}

export function clearReviewData(): void {
  store.dispatch({
    type: 'CLEAR_REVIEW_DATA'
  });
}

export async function resendReportToPatient(
  accessToken: string,
  surgeryId: number
): Promise<string> {
  let message = 'ok';
  try {
    await callStratusApiV2(`surgeries/${surgeryId}/resend_report`, {
      accessToken,
      method: RequestMethod.Post,
      headers: { 'Content-Type': 'application/json' }
    });
  } catch (error) {
    if (error instanceof BadRequestError) {
      message = 'Review your data and try again';
    } else if (error instanceof AuthorizationError) {
      message =
        "Seems you don't have authorization to see this information, please contact your administrator.";
    } else if (error instanceof Error) {
      message = JSON.parse(error.message).message;
    }
  }

  return message;
}

export function setExternalReviewData(
  externalReviewData: KLExternalReview
): void {
  const { postOpInstructions, ...reviewData } =
    camelcaseKeys(externalReviewData);

  store.dispatch({
    type: 'SET_REVIEW_DATA',
    payload: {
      annotations: reviewData.annotatedData
    }
  });
  store.dispatch({
    type: 'SET_SURGERY_DATA',
    payload: {
      surgery_info: reviewData.surgeryInfo,
      surgeon_bio_info: reviewData.surgeonBio
    }
  });
  store.dispatch({
    type: 'SET_ALL_PDF_LIST',
    payload: postOpInstructions
  });
}

export function setPendingDeleteConfirmation(show: boolean): void {
  store.dispatch({
    type: 'SET_PENDING_DELETE_CONFIRMATION',
    payload: show
  });
}

export function setPostDeleteConfirmation(show: boolean): void {
  store.dispatch({
    type: 'SET_POST_DELETE_CONFIRMATION',
    payload: show
  });
}

export function setPendingSurgery(surgeryId: number): void {
  store.dispatch({
    type: 'SET_PENDING_SURGERY',
    payload: surgeryId
  });
}
export async function getSurgery(
  accessToken: string,
  surgeryId: number
): Promise<void> {
  const res = await callStratusApiV2<{
    surgery_info: SurgeryInfo;
    surgery_bio_info: SurgeonBio;
  }>(`surgeries/${surgeryId}`, {
    accessToken,
    method: RequestMethod.Get,
    headers: { 'Content-Type': 'application/json' }
  });

  store.dispatch({
    type: 'SET_SURGERY',
    payload: surgeryInfoToSurgery(
      camelcaseKeys(res.surgery_info, { deep: true })
    )
  });
}

export function clearSurgery(): void {
  store.dispatch({ type: 'CLEAR_SURGERY' });
}
