import { AnyAction } from 'redux';
import { KLUi } from '../types';

const initialState = {
  loading: false,
  loadingMessage: '',
  errors: null,
  filter: {
    id: '',
    value: ''
  },
  surgeryFilters: {
    patientName: '',
    surgeonName: '',
    surgeryDate: '',
    facilityName: '',
    search: ''
  },
  surgeryStatus: {
    notStarted: false,
    inProgress: false,
    processing: false,
    readyForReview: false,
    sentToPatient: false
  },
  pagination: 1,
  showUpdateAccountPage: false,
  editing: false,
  sorting: [],
  progressUpload: {
    total: 0,
    loaded: 0
  }
};

export default function UiReducer(
  state = initialState,
  action: AnyAction
): KLUi {
  switch (action.type) {
    case 'SET_ERRORS':
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    case 'CLEAR_ERRORS':
      return {
        ...state,
        loading: false,
        errors: null
      };
    case 'LOADING_UI':
      return {
        ...state,
        loading: action.payload.isLoading,
        loadingMessage: action.payload.message
      };
    case 'SET_SURGERY_FILTERS':
      return {
        ...state,
        surgeryFilters: action.payload
      };
    case 'SET_REPORT_STATUS_FILTERS':
      return {
        ...state,
        surgeryStatus: action.payload
      };
    case 'SET_PAGINATION':
      return {
        ...state,
        pagination: action.payload
      };

    case 'SET_SHOW_UPDATE_ACCOUNT_PAGE':
      return {
        ...state,
        showUpdateAccountPage: action.payload
      };

    case 'SET_EDITING':
      return {
        ...state,
        editing: action.payload
      };

    case 'SET_SORTING':
      return {
        ...state,
        sorting: action.payload
      };

    case 'SET_PROGRESS':
      return {
        ...state,
        progressUpload: {
          total: state.progressUpload.total,
          loaded: action.payload
        }
      };

    case 'SET_TOTAL_UPLOAD':
      return {
        ...state,
        progressUpload: {
          total: action.payload,
          loaded: state.progressUpload.loaded
        }
      };

    default:
      return state;
  }
}
