import { createTheme, MuiThemeProvider } from '@material-ui/core';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { ReactElement, useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './App.scss';
import KLFooter from './components/footer/KLFooter';
import KLNavBar from './components/navbar/KLNavBar';
import ShortenedUrlRedirect from './components/shortenedUrlRedirect/ShortenedUrlRedirect';
import { GA_MEASUREMENT_ID, LAUNCHDARKLY_SDK_KEY } from './constants';
import TopStart from './hooks/TopStart';
import AddFacilityPage from './pages/addFacility/AddFacilityPage';
import AddSurgeryPage from './pages/addSurgery/AddSurgeryPage';
import ForgotPassword from './pages/cedentialForms/ForgotPassword';
import Login from './pages/cedentialForms/Login';
import ResetPassword from './pages/cedentialForms/ResetPassword';
import SignUp from './pages/cedentialForms/SignUp';
import CookiePolicyPage from './pages/cookiePolicy/CookiePolicyPage';
import DataRetentionPolicyPage from './pages/dataRetentionPolicy/DataRetentionPolicyPage';
import ErrorLinkPage from './pages/errorPage/ErrorLinkPage';
import ErrorPage from './pages/errorPage/ErrorPage';
import FacilitiesPage from './pages/facilitiesPage/Facilities';
import FacilityDetail from './pages/facilityDetail/FacilityDetail';
import HelpPage from './pages/help/HelpPage';
import HIPAANoticePage from './pages/hippaNotice/HIPAANoticePage';
import NonDiscriminationPage from './pages/nonDiscrimination/NonDiscriminationPage';
import ExternalPatientReport from './pages/patientReport/ExternalPatientReport';
import ExternalReport from './pages/patientReport/ExternalReport';
import PatientReport from './pages/patientReport/PatientReport';
import PaymentSuccessPage from './pages/paymentSuccess/PaymentSuccessPage';
import PrivacyPolicyPage from './pages/privacyPolicy/PrivacyPolicyPage';
import ReviewPage from './pages/review/ReviewPageRouter';
import SecurityPage from './pages/security/SecurityPage';
import StaffPage from './pages/staff/StaffPage';
import StyleGuidePage from './pages/styleGuide/StyleGuide';
import SurgeriesPage from './pages/surgeries/SurgeriesPage';
import SurgeryPage from './pages/surgery/SurgeryPage';
import SurgeonTermsOfUsePage from './pages/termsOfUse/SurgeonTermsOfUsePage';
import TermsOfUsePage from './pages/termsOfUse/TermsOfUsePage';
import UnverifiedEmail from './pages/unverifiedEmail/UnverifiedEmail';
import Uploader from './pages/uploader/Uploader';
import UserSettingsPage from './pages/userSetttings/UserSettingsPage';
import { setLoading } from './redux/actions/uiActions';
import { getUserAccessToken, logoutUser } from './redux/actions/userActions';
import { KLState, KLUser } from './redux/types';
import './style.scss';
import './styles/utilities.scss';
import PrivateRoute from './utils/PrivateRoute';
import { themeOptions } from './utils/theme';

interface Routes {
  [key: string]: () => React.ReactElement<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    string | React.JSXElementConstructor<any>
  >;
}
if (GA_MEASUREMENT_ID) {
  ReactGA.initialize(GA_MEASUREMENT_ID);
}

const theme = createTheme(themeOptions);

const routes = {
  surgeryRoutes: [
    '/add-surgery',
    '/surgeries',
    '/surgeries/:id',
    '/surgeries/:id/upload'
  ],
  patientRoutes: [
    '/patient-profile',
    '/surgeries',
    '/surgeries/:id',
    '/surgeries/:id/patient-report',
    '/payments/success',
    '/help'
  ],
  clinicalRoutes: ['/surgeries/:id/review'],
  adminRoutes: ['/staff', '/facilities', '/facility'],
  nonInternalKaliberRoutes: ['/user-settings'],
  adminExclusiveRoutes: ['/add-surgeon', '/add-facility']
};
const routeComponentMapping: Routes = {
  '/add-facility': AddFacilityPage,
  '/add-surgery': AddSurgeryPage,
  '/cookie-policy': CookiePolicyPage,
  '/data-retention-policy': DataRetentionPolicyPage,
  '/errorlink': ErrorLinkPage,
  '/facilities': FacilitiesPage,
  '/facility': FacilityDetail,
  '/help': HelpPage,
  '/hipaa-notice': HIPAANoticePage,
  '/non-discrimination': NonDiscriminationPage,
  '/payments/success': PaymentSuccessPage,
  '/privacy-policy': PrivacyPolicyPage,
  '/report': ExternalPatientReport,
  '/security': SecurityPage,
  '/staff': StaffPage,
  '/style-guide': StyleGuidePage,
  '/surgeries': SurgeriesPage,
  '/surgeries/:id': SurgeryPage,
  '/surgeries/:id/patient-report': PatientReport,
  '/surgeries/:id/review': ReviewPage,
  '/surgeries/:id/upload': Uploader,
  '/terms-of-use': TermsOfUsePage,
  '/terms-of-use/surgeon': SurgeonTermsOfUsePage,
  '/unverified-email': UnverifiedEmail,
  '/user-settings': UserSettingsPage
};

type RoleRoutesMapping = {
  [key: string]: string[];
};

const roleRoutesMapping: RoleRoutesMapping = {
  'Kaliber Admin': [
    ...routes.surgeryRoutes,
    ...routes.clinicalRoutes,
    ...routes.patientRoutes,
    ...routes.adminRoutes,
    ...routes.adminExclusiveRoutes
  ],
  Surgeon: [
    ...routes.surgeryRoutes,
    ...routes.clinicalRoutes,
    ...routes.patientRoutes,
    ...routes.adminRoutes,
    ...routes.nonInternalKaliberRoutes
  ],
  'Surgical Clinical Team': [
    ...routes.surgeryRoutes,
    ...routes.clinicalRoutes,
    ...routes.patientRoutes,
    ...routes.adminRoutes,
    ...routes.nonInternalKaliberRoutes
  ],
  'Surgical Office Staff': [
    ...routes.surgeryRoutes,
    ...routes.clinicalRoutes,
    ...routes.patientRoutes,
    ...routes.adminRoutes,
    ...routes.nonInternalKaliberRoutes
  ],
  Patient: [...routes.patientRoutes, ...routes.nonInternalKaliberRoutes],
  'Kaliber Qa': [...routes.surgeryRoutes, ...routes.clinicalRoutes]
};
const buildUserRoutes = (user: KLUser): string[] => {
  return roleRoutesMapping[user.role] || [];
};

function App(): ReactElement {
  const user = useSelector((state: KLState) => state.user);
  const userRoutes = useMemo(() => buildUserRoutes(user), [user]);

  // const query = useQuery();
  // const linkToPatientKey = query.get('key');

  useEffect(() => {
    setLoading(true);
  }, []);

  // Commenting this out until we implement multiple surgeries per patient.
  // This code caused a race condition where a user was inadvertently linked
  // to a new patient record when we didn't intend for that to happen - mstallmo 08/22/23
  // useEffect(() => {
  //   const linkPatientAsync = async () => {
  //     if (linkToPatientKey && user.accessToken) {
  //       try {
  //         await linkPatient(user.accessToken, linkToPatientKey);
  //       } catch (error) {
  //         // console.error('Error linking patient: ', error);
  //       }
  //     }
  //   };
  //   linkPatientAsync();
  // }, [linkToPatientKey, user.accessToken]);

  useEffect(() => {
    const getUserAccessTokenAsync = async () => {
      if (!user.accessToken) {
        await getUserAccessToken();
      }
    };
    getUserAccessTokenAsync();
  }, [user.accessToken]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let userActivityTimeout: any = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let userActivityThrottlerTimeout: any = null;
  const INACTIVE_USER_TIME_THRESHOLD = 1800000;
  const USER_ACTIVITY_THROTTLER_TIME = 60000; // the period for which all user activity will not trigger the tracking logic again
  useEffect(() => {
    const activateActivityTracker = () => {
      const userActivityThrottler = () => {
        if (!userActivityThrottlerTimeout) {
          userActivityThrottlerTimeout = setTimeout(() => {
            resetUserActivityTimeout();
            clearTimeout(userActivityThrottlerTimeout);
            userActivityThrottlerTimeout = null;
          }, USER_ACTIVITY_THROTTLER_TIME);
        }
      };
      const resetUserActivityTimeout = () => {
        clearTimeout(userActivityTimeout);
        userActivityTimeout = setTimeout(() => {
          inactiveUserAction();
        }, INACTIVE_USER_TIME_THRESHOLD);
      };
      const inactiveUserAction = () => {
        logoutUser();
      };
      window.addEventListener('mousemove', userActivityThrottler);
      window.addEventListener('scroll', userActivityThrottler);
      window.addEventListener('keydown', userActivityThrottler);
      window.addEventListener('resize', userActivityThrottler);
      window.addEventListener('keepAlive', userActivityThrottler);
      return () => {
        window.removeEventListener('mousemove', userActivityThrottler);
        window.removeEventListener('scroll', userActivityThrottler);
        window.removeEventListener('keydown', userActivityThrottler);
        window.removeEventListener('resize', userActivityThrottler);
        window.removeEventListener('keepAlive', userActivityThrottler);
      };
    };
    activateActivityTracker();
  }, []);
  return (
    <MuiThemeProvider theme={theme}>
      {user.accessToken !== null && (
        <BrowserRouter>
          <TopStart />
          {user.accessToken ? <KLNavBar /> : null}
          <div className="global-container">
            <Switch>
              <PrivateRoute
                exact
                path="/"
                key="/"
                component={() => <Redirect to="/surgeries" />}
              />
              {userRoutes.map(route => (
                <PrivateRoute
                  exact
                  path={route}
                  key={route}
                  component={routeComponentMapping[route]}
                />
              ))}
              <Route exact path="/login" key="/login" component={Login} />
              {/* Commenting out this route until we're ready to release this feature to users. - mstallmo */}
              <Route exact path="/signup" key="/signup" component={SignUp} />
              <Route
                exact
                path="/forgot-password"
                key="/forgot-password"
                component={ForgotPassword}
              />
              <Route
                exact
                path="/reset-password"
                key="/reset-password"
                component={ResetPassword}
              />
              <Route
                path="/unverified-email"
                key="unverified-email"
                component={UnverifiedEmail}
              />
              <Route path="/report" key="/report" component={ExternalReport} />
              <Route
                exact
                path="/terms-of-use"
                key="/terms-of-use"
                component={TermsOfUsePage}
              />
              <Route
                exact
                path="/terms-of-use/surgeon"
                key="/terms-of-use/surgeon"
                component={SurgeonTermsOfUsePage}
              />
              <Route
                exact
                path="/privacy-policy"
                key="privacy-policy"
                component={PrivacyPolicyPage}
              />
              <Route
                exact
                path="/hipaa-notice"
                key="/hipaa-notice"
                component={HIPAANoticePage}
              />
              {user && buildUserRoutes(user)}
              <Route
                exact
                path="/non-discrimination"
                key="/non-discrimination"
                component={NonDiscriminationPage}
              />
              <Route exact path="/cookie-policy" component={CookiePolicyPage} />
              <Route
                exact
                path="/data-retention-policy"
                key="/data-retention-policy"
                component={DataRetentionPolicyPage}
              />
              <Route exact path="/security" component={SecurityPage} />
              <Route exact path="/errorlink" component={ErrorLinkPage} />
              {/*TODO Delete after testing*/}
              <Route exact path="/errorpage" component={ErrorPage} />
              <Route
                exact
                path="/privacy-policy"
                key="/privacy-policy"
                component={PrivacyPolicyPage}
              />
              <Route
                exact
                path="/style-guide"
                key="/style-guide"
                component={StyleGuidePage}
              />
              <Route path="/s/:shortKey" component={ShortenedUrlRedirect} />
              <Redirect to="/" />
              {/* {isLoading ? null : <Route component={ErrorPage} />} */}
            </Switch>
          </div>
          {user.accessToken ? <KLFooter /> : null}
        </BrowserRouter>
      )}
    </MuiThemeProvider>
  );
}

export default withLDProvider({
  clientSideID: LAUNCHDARKLY_SDK_KEY
})(App);
