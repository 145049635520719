import store from '../store';

export function setLoading(loading: boolean, message?: string): void {
  store.dispatch({
    type: 'LOADING_UI',
    payload: { isLoading: loading, message: message }
  });
}

/**
 * Sets a surgery filter to a given value.
 *
 * @param {string} id The id of the filter to set. This is the key used in the `surgeryFilters` reducer.
 * @param {string} value The value to set the filter to.
 */
export function setSurgeryFiltersStore(id: string, value: string): void {
  const filters = store.getState().ui.surgeryFilters;
  filters[id] = value;
  store.dispatch({
    type: 'SET_SURGERY_FILTERS',
    payload: filters
  });
}

/**
 * Sets a report status filter to a given value.
 *
 * @param {string} id The id of the filter to set. This is the key used in the `surgeryStatus` reducer.
 * @param {boolean} value The value to set the filter to.
 */
export function setReportStatusFiltersStore(id: string, value: boolean): void {
  const filters = store.getState().ui.surgeryStatus;
  filters[id] = value;
  store.dispatch({
    type: 'SET_REPORT_STATUS_FILTERS',
    payload: filters
  });
}

/**
 * Sets the current page number in the ui reducer.
 *
 * @param {number} page The page number to set.
 */
export function setPaginationStore(page: number): void {
  store.dispatch({
    type: 'SET_PAGINATION',
    payload: page
  });
}

export function setShowUpdateAccountPage(val: boolean): void {
  store.dispatch({
    type: 'SET_SHOW_UPDATE_ACCOUNT_PAGE',
    payload: val
  });
}
export function setEditing(value: boolean): void {
  store.dispatch({
    type: 'SET_EDITING',
    payload: value
  });
}
export function setProgress(loaded: number): void {
  store.dispatch({
    type: 'SET_PROGRESS',
    payload: loaded
  });
}
export function setTotalUpload(total: number): void {
  store.dispatch({
    type: 'SET_TOTAL_UPLOAD',
    payload: total
  });
}
