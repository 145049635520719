import moment from 'moment';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FilterGrey from '../../../../../assets/icons/faders-horizontal-grey.svg';
import FilterTeal from '../../../../../assets/icons/faders-horizontal-teal.svg';
import { KaliberButton, KaliberSelect } from '../../../../../components';

import KaliberSingleDatePicker from '../datepicker/KaliberSingleDatePicker';
import './FiltersMenu.scss';
import { FilterData } from '../../../../../redux/types';

interface FilterMenuProps {
  surgeonNames: string[];
  onSubmit: (filterData: FilterData) => void;
  filter: FilterData;
  facilities: string[];
}

export default function FilterMenu({
  surgeonNames,
  onSubmit,
  filter,
  facilities
}: FilterMenuProps): ReactElement {
  const {
    handleSubmit,
    control,
    setValue
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useForm({
    defaultValues: {
      search: filter.search ?? '',
      surgeonName: filter.surgeonName ?? '',
      facilityName: filter.facilityName ?? '',
      surgeryDate: filter.surgeryDate ?? ''
    },
    values: {
      search: filter.search ?? '',
      surgeonName: filter.surgeonName ?? '',
      facilityName: filter.facilityName ?? '',
      surgeryDate: filter.surgeryDate ?? ''
    }
  });

  const [showFiltersMenu, setShowFiltersMenu] = useState(false);

  const useOutsideClick = (show: boolean, setShow: (show: boolean) => void) => {
    const menuRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setShow(false);
      }
    };

    useEffect(() => {
      if (show) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [show, setShow]);

    return { menuRef, buttonRef };
  };

  const { menuRef, buttonRef } = useOutsideClick(
    showFiltersMenu,
    setShowFiltersMenu
  );

  const handleApply = (filterData: FilterData) => {
    onSubmit(filterData);
    setShowFiltersMenu(false);
  };

  const handleClear = () => {
    setValue('surgeonName', '');
    setValue('facilityName', '');
    setValue('surgeryDate', '');
  };

  const numbActiveFilters = useMemo(() => {
    return Object.values(filter).reduce((count, value) => {
      if (value) count++;
      return count;
    }, 0);
  }, [filter]);

  return (
    <div className="filters">
      {Object.values(filter).every(v => v === '') ? (
        <button
          ref={buttonRef}
          className="filters__button"
          onClick={() => setShowFiltersMenu(prev => !prev)}>
          <div className="filters__text">Filters</div>
          <div className="filters__icon">
            <img src={FilterGrey} />
          </div>
        </button>
      ) : (
        <button
          ref={buttonRef}
          className="filters__button--teal"
          onClick={() => setShowFiltersMenu(prev => !prev)}>
          <div className="filters__text--teal">
            Filters ({numbActiveFilters})
          </div>
          <div className="filters__icon">
            <img src={FilterTeal} />
          </div>
        </button>
      )}

      {showFiltersMenu && (
        <form onSubmit={handleSubmit(handleApply)}>
          <div className="filters__menu" ref={menuRef}>
            <Controller
              name="surgeonName"
              control={control}
              render={({ field }) => (
                <KaliberSelect
                  label="Surgeon"
                  placeholder=""
                  select={field.value}
                  includeOther={false}
                  onChange={e => field.onChange(e)}
                  list={surgeonNames}
                />
              )}
            />
            <Controller
              name="facilityName"
              control={control}
              render={({ field }) => (
                <KaliberSelect
                  label="Surgery Facility"
                  placeholder=""
                  select={field.value}
                  includeOther={false}
                  onChange={e => field.onChange(e)}
                  list={facilities}
                />
              )}
            />
            <Controller
              name="surgeryDate"
              control={control}
              render={({ field }) => (
                <KaliberSingleDatePicker
                  id="surgery-date-filter"
                  label="Surgery Date"
                  onChange={e => field.onChange(e)}
                  date={field.value === '' ? null : moment(field.value)}
                />
              )}
            />

            <div className="menu__buttons">
              <KaliberButton
                buttonClass="btn--secondary--small"
                type="reset"
                onClick={() => handleClear()}>
                Clear
              </KaliberButton>
              <KaliberButton buttonClass="btn--primary--small" type="submit">
                Apply
              </KaliberButton>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
